import React from "react";

import { useAtom } from "jotai";
import * as S from "../Components/Login/styled";
import { onLoginClickAtom } from "../ViewModels/LoginViewModel";
import { useNavigate } from "react-router-dom";
import { loginValueAtom } from "../Atoms/LoginAtom";
import { Box } from "@mui/material";
import NeedToChangePasswordModal from "../Components/Login/Modals/NeedToChangePassword";
import ImageSection from "../Components/Common/ImageSection";

const Modals = () => {
  return (
    <NeedToChangePasswordModal />
  )
}

export function Login() {
  const [, onLoginClick] = useAtom(onLoginClickAtom);
  const [loginValue, setLoginValue] = useAtom(loginValueAtom);

  const navigate = useNavigate();

  return (
    <S.LoginFullContainer>
      <S.Wrapper>
        <ImageSection pathTo="/signup" />
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <S.InputField
            type="text"
            name="email"
            placeholder="Email"
            value={loginValue.username}
            onChange={(e) =>
              setLoginValue((current) => ({
                ...current,
                username: e.target.value,
              }))
            }
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                onLoginClick({ navigate });
              }
            }}
          />
          <S.InputField
            type="password"
            name="password"
            placeholder="Password"
            value={loginValue.password}
            onChange={(e) =>
              setLoginValue((current) => ({
                ...current,
                password: e.target.value,
              }))
            }
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                onLoginClick({ navigate });
              }
            }}
          />
          <S.SubmitButton
            type="submit"
            value="Login"
            onClick={() => onLoginClick({ navigate })}
          >
            Login
          </S.SubmitButton>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "90%" }}>
            <S.SignupButton onClick={() => navigate("/signup")}>
              Sign up
            </S.SignupButton>
            <S.SignupButton onClick={() => navigate("/find-pw")}>
              비밀번호 찾기
            </S.SignupButton>
          </div>
        </Box>
      </S.Wrapper>
      <Modals />
    </S.LoginFullContainer>
  );
}
