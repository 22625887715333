import { atom } from "jotai";

interface LoginValueProps {
    username: string;
    password: string;
}

export const loginValueAtom = atom<LoginValueProps>({
    username: "",
    password: "",
});