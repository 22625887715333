import React from "react";
import { Box, Modal, Typography, Button } from "@mui/material";
import { useAtom } from "jotai";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"; // MUI icon
import { useNavigate } from "react-router-dom";

import * as S from "./styled";
import { errorModalAtom } from "../../Atoms/RootAtom";

const ErrorModal: React.FC = () => {
  const [errorState, setErrorState] = useAtom(errorModalAtom);
  const navigate = useNavigate();

  const handleClose = () => {
    setErrorState((prev) => ({ ...prev, state: false }));
  };

  const handleRedirect = () => {
    navigate(errorState.redirectUrl);
    handleClose();
  };

  return (
    <Modal
      open={errorState.state}
      aria-labelledby="modal-error-title"
      aria-describedby="modal-error-description"
    >
      <S.CustomBox>
        <ErrorOutlineIcon sx={{ fontSize: 50, color: "red" }} />{" "}
        {/* Danger icon */}
        <Typography
          id="modal-error-title"
          variant="h6"
          component="h2"
          sx={{ mt: 2 }}
        >
          {errorState.title}
        </Typography>
        {errorState.text.split("\n").map((sent, index) => (
          <Typography
            key={`modal-description-error-${index}`}
            sx={{ mt: 2, fontSize: 14 }}
          >
            {sent}
          </Typography>
        ))}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mx: 1,
              background: "#8f8f8f",
              "&:hover": {
                background: "#8f8f8f",
              },
            }}
          >
            닫기
          </Button>
          {errorState.redirectUrl !== "" && (
            <Button
              variant="contained"
              onClick={handleRedirect}
              sx={{
                mx: 1,
                background: "#3B0095",
                "&:hover": {
                  background: "#3B0095",
                },
              }}
            >
              이동
            </Button>
          )}
          {errorState.event !== null && (
            <Button
              variant="contained"
              onClick={errorState.event}
              sx={{
                mx: 1,
                background: "#3B0095",
                "&:hover": {
                  background: "#3B0095",
                },
              }}
            >
              {errorState.eventText}
            </Button>
          )}
        </Box>
      </S.CustomBox>
    </Modal>
  );
};

export default ErrorModal;
