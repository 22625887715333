import {
  Button,
  styled as muiStyled,
} from "@mui/material";
import theme from "../../../Styles/theme";
import { lighten } from "polished";

export const CustomButton = muiStyled(Button)(() => ({
  borderRadius: "20px",
  backgroundColor: theme.colors.primary,
  '&:hover': {
    backgroundColor: lighten(0.1, theme.colors.primary),
  },
  '&:active': {
    backgroundColor: lighten(0.2, theme.colors.primary),
  },
  '&.Mui-disabled': {
    backgroundColor: lighten(0.4, theme.colors.primary), // 더 밝은 색상
    color: '#fff', // 텍스트 색상
  },
}));
