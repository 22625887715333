import {
  Box,
  Button,
  IconButton,
  Input,
  InputBase,
  styled as muiStyled,
  Stack,
} from "@mui/material";
import styled from "styled-components";
import theme from "../../../../Styles/theme";
import { lighten } from "polished";

export const CustomStack = muiStyled(Stack)({
  width: "100%",
  gap: "5px",
  alignItems: "start",
  "@media (max-width: 768px)": {
    marginBottom: "15px", // 모바일에서 마진 조정
    marginTop: "8px", // 모바일에서 마진 조정
  },
});

export const InputContainer = muiStyled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "30px",
  padding: "5px 10px",
  flex: 1,
  width: "calc(100% - 22px)",
  outline: "none",
  boxShadow: "0 0 10px rgba(164, 159,248, 0.2)",
  color: "#393193",
  transition: "background 0.3s ease",
  border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
  "@media (max-width: 768px)": {
    padding: "8px 12px", // 모바일에서 패딩 조정
  },
  gap: "10px",
}));

export const CustomInputBase = muiStyled(InputBase)(({ theme }) => ({
  flex: 1,
  marginLeft: "15px",
  "@media (max-width: 768px)": {
    marginLeft: "10px", // 모바일에서 마진 조정
  },
}));

export const CustomSecretInputBase = muiStyled(Input)(({ theme }) => ({
  flex: 1,
  marginLeft: "15px",
  "@media (max-width: 768px)": {
    marginLeft: "10px", // 모바일에서 마진 조정
  },
}));

export const CustomButton = muiStyled(Button)(() => ({
  backgroundColor: theme.colors.primary,
  fontSize: "0.9rem",
  '&:hover': {
    backgroundColor: lighten(0.1, theme.colors.primary),
  },
  '&:active': {
    backgroundColor: lighten(0.2, theme.colors.primary),
  },
  '&.Mui-disabled': {
    backgroundColor: lighten(0.4, theme.colors.primary), // 더 밝은 색상
    color: '#fff', // 텍스트 색상
  },
}));

export const CustomIconButton = muiStyled(IconButton)`
    align-self: end;
    @media (max-width: 768px) {
        padding: 8px; // 모바일에서 패딩 줄임
    }
`;
