import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { needToChangePasswordModalAtom } from '../../../../Atoms/Login/NeedToChangePasswordModalAtom';
import * as S from './styled';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import WarningIcon from '@mui/icons-material/Warning';
import { needToChangePasswordModalChangePasswordAtom, needToChangePasswordModalCloseAtom, needToChangePasswordModalPasswordTestAtom } from '../../../../ViewModels/Login/NeedToChangePasswordViewModel';

const NeedToChangePasswordModal = () => {
  const [modal, setModal] = useAtom(needToChangePasswordModalAtom);
  const handleChangeMode = () => setModal((prev) => ({ ...prev, mode: "change" }));
  const changePWValue = (e: React.ChangeEvent<HTMLInputElement>) => setModal((prev) => ({ ...prev, password: e.target.value }));
  const changeConfirmPWValue = (e: React.ChangeEvent<HTMLInputElement>) => setModal((prev) => ({ ...prev, confirmPassword: e.target.value }));
  const checkPassword = useAtomValue(needToChangePasswordModalPasswordTestAtom);
  const closeModal = useSetAtom(needToChangePasswordModalCloseAtom);
  const changePassword = useSetAtom(needToChangePasswordModalChangePasswordAtom);

  const handleConfirmMode = () => {
    if (checkPassword && modal.password === modal.confirmPassword) {
      changePassword();
    }
  }

  return (
    <Dialog
      open={modal.open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="modal-title" sx={{ fontSize: "1rem", fontWeight: "bold" }}>
        {
          modal.mode === "need" ? (
            "비밀번호 변경 필요"
          ) : (
            "비밀번호 변경"
          )
        }
      </DialogTitle>
      <DialogContent id="modal-description" sx={{ fontSize: "0.9rem", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "20px" }}>
        {
          modal.mode === "need" ? (<>첫 로그인 시 비밀번호 변경이 필요합니다.</>) :
            modal.mode === "change" ? (
              <>
                <S.CustomStack>
                  <Typography
                    sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
                  >
                    PW 입력
                  </Typography>
                  <S.InputContainer>
                    <S.CustomInputBase
                      type="password"
                      value={modal.password}
                      onChange={changePWValue}
                    />
                    <S.CustomIconButton tabIndex={-1}>
                      {checkPassword ? (
                        <CheckCircleSharpIcon
                          sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                        />
                      ) : (
                        <Tooltip title="비밀번호는 최소 8자 이상이어야 하며, 대문자, 숫자, 특수문자를 포함해야 합니다.">
                          <WarningIcon sx={{ color: "red" }} />
                        </Tooltip>
                      )}
                    </S.CustomIconButton>
                  </S.InputContainer>
                </S.CustomStack>

                <S.CustomStack>
                  <Typography
                    sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
                  >
                    PW 확인
                  </Typography>
                  <S.InputContainer>
                    <S.CustomInputBase
                      id="confirmPW"
                      type="password"
                      onChange={changeConfirmPWValue}
                    />
                    <S.CustomIconButton tabIndex={-1}>
                      {checkPassword && modal.password === modal.confirmPassword ? (
                        <CheckCircleSharpIcon
                          sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                        />
                      ) : (
                        <Tooltip title="비밀번호와 동일해야 합니다.">
                          <WarningIcon sx={{ color: "red" }} />
                        </Tooltip>
                      )}
                    </S.CustomIconButton>
                  </S.InputContainer>
                </S.CustomStack>
              </>
            ) : (
              <>
                <CheckCircleSharpIcon sx={{ fontSize: 50, color: process.env.REACT_APP_MAIN_COLOR }} />
                <Typography
                  id="modal-success-title"
                  variant="h6"
                  component="h2"
                  sx={{ mt: 2 }}
                >
                  비밀번호 변경 완료
                </Typography>
                <Typography
                  sx={{ mt: 2, fontSize: 14 }}
                >
                  비밀번호 변경이 완료되었습니다. 다시 로그인해주세요.
                </Typography>
              </>
            )
        }
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
        {
          modal.mode === "need" ? (
            <S.CustomButton variant='contained' onClick={handleChangeMode}>확인</S.CustomButton>
          ) : modal.mode === "change" ? (
            <S.CustomButton variant='contained' onClick={handleConfirmMode}>변경</S.CustomButton>
          ) : (
            <S.CustomButton variant='contained' onClick={closeModal}>확인</S.CustomButton>
          )
        }
      </DialogActions>
    </Dialog>
  );
}

export default NeedToChangePasswordModal;
