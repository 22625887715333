import axios from "axios";

export const verifyGroupCode = async (groupCode: string): Promise<string | boolean> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/verify_group_code?group_code=${groupCode}`
    )
    if (response.status !== 200) {
      return false;
    } else {
      const data = response.data;
      return data.group_name;
    }
  } catch (error) {
    return false;
  }
}
