import { atom } from "jotai";

interface SignupProps {
  userType: string;
  groupCode: string;
  checkGroupCode: boolean;
  email: string;
  checkEmail: boolean;
  checkDupEmail: boolean;
  userInputEmailVerifyNum: string;
  realEmailVerifyNum: string;
  checkuserInputEmailVerifyNum: boolean;
  password: string;
  checkPassword: boolean;
  confirmPassword: string;
  checkconfirmPassword: boolean;
  nickname: string;
  checkNickname: boolean;
  allPass: boolean;
  birthDay: Date | null;
}

export const initialSignupModelAtom = {
  userType: "free",
  groupCode: "",
  checkGroupCode: false,
  email: "",
  checkEmail: false,
  checkDupEmail: false,
  userInputEmailVerifyNum: "",
  realEmailVerifyNum: "",
  checkuserInputEmailVerifyNum: false,
  password: "",
  checkPassword: false,
  confirmPassword: "",
  checkconfirmPassword: false,
  nickname: "",
  birthDay: null,
  checkNickname: false,
  allPass: false,
};

export const signupModelAtom = atom<SignupProps>(initialSignupModelAtom);

export const emailSendingStateAtom = atom("");
export const agreementAtom = atom([false, false, false, false]);
