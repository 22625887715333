import { atom } from 'jotai';
import { applyGroupCodeModalAtom } from '../../Atoms/Signup/ApplyGroupCodeModalAtom';
import { verifyGroupCode } from '../../Queries/Signup/GroupCodeQueries';
import { applyGroupCodeAtom } from '../SignupViewModel';
import { signupModelAtom } from '../../Atoms/SignupAtom';

// 그룹 코드 입력 상태 관리
export const groupCodeInputStateAtom = atom((get) => {
  const modal = get(applyGroupCodeModalAtom);
  return modal.isVerified && !modal.open && modal.groupCode !== "" && modal.groupName !== "";
})

export const applyGroupCodeModalClearAtom = atom(null, (_get, set) => {
  set(signupModelAtom, (prev) => ({
    ...prev,
    groupCode: ""
  }));
  set(applyGroupCodeModalAtom, {
    open: false,
    groupCode: "",
    groupName: "",
    isVerified: false
  });
});

export const applyGroupCodeModalGetGroupNameAtom = atom((get) => get(applyGroupCodeModalAtom).groupName);

export const applyGroupCodeModalEditGroupCodeAtom = atom(
  (get) => get(applyGroupCodeModalAtom).groupCode,
  (_get, set, update: string) => {
    set(applyGroupCodeModalAtom, (prev) => ({ ...prev, groupCode: update }));
  }
)

export const applyGroupCodeModalVerifyAtom = atom(null, async (get, set) => {
  const groupCode = get(applyGroupCodeModalAtom).groupCode;
  if (groupCode === "") return;

  // API 호출
  const response = await verifyGroupCode(groupCode);
  if (response === false) {
    set(applyGroupCodeModalAtom, (prev) => ({ ...prev, open: true, groupName: "", isVerified: false }));
    return;
  } else {
    set(applyGroupCodeModalAtom, (prev) => ({ ...prev, open: true, groupName: response as string, isVerified: true }));
  }
})

export const applyGroupCodeModalSetGroupAtom = atom(null, (get, set) => {
  const groupCode = get(applyGroupCodeModalAtom).groupCode;
  set(applyGroupCodeAtom, groupCode);
  set(applyGroupCodeModalAtom, (prev) => ({ ...prev, open: false }));
});
