import { ResponseStatus } from "../Enum/NetworkEnum";
import { setCookie } from "../Utils/CookieUtil";

export async function getUserInfoWhenStart(access: string) {
  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/getUserInfoWhenStart`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    }
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export async function getVerifyRefreshToken(refresh: string) {
  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/refresh/verify`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${refresh}`,
      },
    }
  );

  if (response.status === 200) {
    const result = await response.json();

    // 쿠키에 액세스 토큰 저장
    setCookie(
      process.env.REACT_APP_USER_ACCESS_COOKIE_ID,
      result.access_token,
      {
        path: "/",
        sameSite: "Lax",
        domain: process.env.REACT_APP_DOMAIN_NAME,
        secure: true,
      }
    );

    return result.access_token;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}
