import { atom } from 'jotai';

interface needToChangePasswordModalAtomProps {
  open: boolean;
  mode: string;
  password: string;
  confirmPassword: string;
  accessToken: string;
  refreshToken: string;
}

export const needToChangePasswordModalInitState: needToChangePasswordModalAtomProps = {
  open: false,
  mode: 'need',
  password: '',
  confirmPassword: '',
  accessToken: '',
  refreshToken: '',
};

export const needToChangePasswordModalAtom = atom<needToChangePasswordModalAtomProps>(needToChangePasswordModalInitState);
