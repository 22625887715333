import { atom } from "jotai";
import {
  agreementAtom,
  emailSendingStateAtom,
  initialSignupModelAtom,
  signupModelAtom,
} from "../Atoms/SignupAtom";
import {
  errorModalAtom,
  fromLandingPageInterviewDataAtom,
  noticeModalAtom,
  successModalAtom,
  waitingModalAtom,
} from "../Atoms/RootAtom";
import { sendEmail, signup, checkUserEmail } from "../Queries/SignupQueries";
import { userAtom } from "../Atoms/PublicAtom";
import dayjs from "dayjs";
import { error } from "console";

export const checkTypeSelectAtom = atom(
  (get) => get(fromLandingPageInterviewDataAtom),
  (get, set, type: string) => {
    set(signupModelAtom, (current) => ({ ...current, userType: type }));
  }
);

export const onEmailSendClickAtom = atom(null, async (get, set) => {
  const checkEmail = get(signupModelAtom).checkEmail;

  if (checkEmail) {
    const email = get(signupModelAtom).email;

    // checkDupEmail
    const checkResponse = await checkUserEmail(email);
    console.log(checkResponse);

    if (checkResponse === 200) {
      // 사용 가능한 이메일
      set(waitingModalAtom, { state: true, text: "잠시만 기다려주세요." });

      const response = await sendEmail(email);
      if (response) {
        set(signupModelAtom, (current) => ({
          ...current,
          realEmailVerifyNum: response,
          userInputEmailVerifyNum: "",
          checkuserInputEmailVerifyNum: false,
          checkDupEmail: true,
        }));
        set(waitingModalAtom, { state: false, text: "잠시만 기다려주세요." });
      }
    } else if (checkResponse === 201) {
      // 이미 가입된 이메일이지만 연동이 가능함
      set(noticeModalAtom, {
        state: true,
        redirectUrl: "/",
        text: "다른 서비스를 이용중이십니다.\n로그인하여 계정을 연동해주세요.",
        title: "서비스 연동",
        event: null,
        eventText: "",
      });
    } else if (checkResponse === 202) {
      // 중복된 이메일
      set(signupModelAtom, (current) => ({
        ...current,
        checkDupEmail: false,
      }));
      set(emailSendingStateAtom, "dup");
    } else {
      // 네트워크 오류
      set(errorModalAtom, {
        state: true,
        redirectUrl: "",
        text: "네트워크 오류가 발생했습니다.\n잠시 뒤에 시도해주세요.",
        title: "네트워크 오류",
        event: null,
        eventText: "",
      });
    }
  }
});

export const checkUserAndNavigateAtom = atom(
  (get) => get(userAtom),
  (get, set) => {
    const user = get(userAtom);

    if (user) {
      return true;
    } else {
      return false;
    }
  }
);

export const changeEmailValueAtom = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(signupModelAtom, (current) => ({
      ...current,
      email: event.target.value,
    }));

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(event.target.value)) {
      set(signupModelAtom, (current) => ({ ...current, checkEmail: true }));
    } else {
      set(signupModelAtom, (current) => ({ ...current, checkEmail: false }));
    }
  }
);

export const changeEmailVerifyValueAtom = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(signupModelAtom, (current) => ({
      ...current,
      userInputEmailVerifyNum: event.target.value,
    }));

    const verifyNum = get(signupModelAtom).realEmailVerifyNum;

    if (event.target.value === verifyNum && verifyNum !== "") {
      set(signupModelAtom, (current) => ({
        ...current,
        checkuserInputEmailVerifyNum: true,
      }));
    } else {
      set(signupModelAtom, (current) => ({
        ...current,
        checkuserInputEmailVerifyNum: false,
      }));
    }
  }
);

export const changePWValueAtom = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const signupModel = get(signupModelAtom);

    if (
      signupModel.checkuserInputEmailVerifyNum === true &&
      signupModel.userInputEmailVerifyNum === ""
    ) {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "챗봇 빌더 연동 하시는 유저들은 \n비밀번호 변경이 불가능합니다.",
        title: "비밀번호 변경 불가",
      });
    } else {
      set(signupModelAtom, (current) => ({
        ...current,
        password: event.target.value,
      }));

      const passwordRegex =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;

      if (passwordRegex.test(event.target.value)) {
        set(signupModelAtom, (current) => ({
          ...current,
          checkPassword: true,
        }));
      } else {
        set(signupModelAtom, (current) => ({
          ...current,
          checkPassword: false,
        }));
      }

      const confirmPassword = get(signupModelAtom).confirmPassword;

      if (event.target.value === confirmPassword && confirmPassword !== "") {
        set(signupModelAtom, (current) => ({
          ...current,
          checkconfirmPassword: true,
        }));
      } else {
        set(signupModelAtom, (current) => ({
          ...current,
          checkconfirmPassword: false,
        }));
      }
    }
  }
);

export const changeConfirmPWValueAtom = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const signupModel = get(signupModelAtom);

    if (
      signupModel.checkuserInputEmailVerifyNum === true &&
      signupModel.userInputEmailVerifyNum === ""
    ) {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "챗봇 빌더 연동 하시는 유저들은 \n비밀번호 변경이 불가능합니다.",
        title: "비밀번호 변경 불가",
      });
    } else {
      set(signupModelAtom, (current) => ({
        ...current,
        confirmPassword: event.target.value,
      }));

      const password = get(signupModelAtom).password;

      if (event.target.value === password && password !== "") {
        set(signupModelAtom, (current) => ({
          ...current,
          checkconfirmPassword: true,
        }));
      } else {
        set(signupModelAtom, (current) => ({
          ...current,
          checkconfirmPassword: false,
        }));
      }
    }
  }
);

export const changeNicknameAtom = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(signupModelAtom, (current) => ({
      ...current,
      nickname: event.target.value,
    }));

    const nickname = get(signupModelAtom).nickname;

    if (event.target.value.length >= 2) {
      set(signupModelAtom, (current) => ({
        ...current,
        checkNickname: true,
      }));
    } else {
      set(signupModelAtom, (current) => ({
        ...current,
        checkNickname: false,
      }));
    }
  }
);

export const changeBirthdayAtom = atom(null, (get, set, date: dayjs.Dayjs) => {
  set(signupModelAtom, (current) => ({
    ...current,
    birthDay: date.toDate(),
  }));
});

export const checkAllValueAtom = atom(null, (get, set) => {
  const signupModel = get(signupModelAtom);
  console.log("checkAllValueAtom", signupModel);
  if (
    signupModel.checkEmail &&
    signupModel.checkDupEmail &&
    signupModel.checkuserInputEmailVerifyNum &&
    signupModel.checkPassword &&
    signupModel.checkconfirmPassword &&
    signupModel.checkNickname
  )
    set(signupModelAtom, (current) => ({ ...current, allPass: true }));
  else
    set(errorModalAtom, {
      state: true,
      redirectUrl: "",
      text: "입력하신 것 중에 잘못 된 부분이 있습니다.\n 확인 후 다시 시도해주세요.",
      title: "회원가입 입력 오류",
      event: null,
      eventText: "",
    });
});

export const finallySignUpAtom = atom(null, async (get, set, { navigate }) => {
  const signupModel = get(signupModelAtom);
  const agree = get(agreementAtom);

  if (
    signupModel.checkEmail &&
    signupModel.checkuserInputEmailVerifyNum &&
    signupModel.checkPassword &&
    signupModel.checkconfirmPassword &&
    signupModel.userType !== "" &&
    agree[0] &&
    agree[1] &&
    agree[2]
  ) {
    const data = {
      email: signupModel.email,
      password: signupModel.password,
      site: process.env.REACT_APP_SITE_NAME,
      membership: signupModel.userType,
      marketing_agreement: agree[3],
      nickname: signupModel.nickname,
      birth_day: signupModel.birthDay,
      ...(signupModel.checkGroupCode && { group_code: signupModel.groupCode }),
    };

    const response = await signup(data);

    if (response) {
      set(agreementAtom, [false, false, false, false]);

      set(signupModelAtom, initialSignupModelAtom);

      navigate("/");

      set(successModalAtom, {
        state: true,
        redirectUrl: "",
        text: "회원가입이 되었습니다.\n자신만의 커리어 플랜을 세워보세요.",
        title: "회원가입 성공",
        event: null,
        eventText: "",
        isClose: true,
      });
    } else {
      set(errorModalAtom, {
        state: true,
        redirectUrl: "",
        text: "네트워크 오류가 발생했습니다.\n잠시 뒤에 시도해주세요.",
        title: "네트워크 오류",
        event: null,
        eventText: "",
      });
    }
  } else if (signupModel.userType === "") {
    set(errorModalAtom, {
      state: true,
      redirectUrl: "",
      text: "유저타입을 선택해 주세요.",
      title: "유저타입 선택",
      event: null,
      eventText: "",
    });
  } else if (!agree[0] || !agree[1] || !agree[2]) {
    set(errorModalAtom, {
      state: true,
      redirectUrl: "",
      text: "필수로 표시된 약관은 동의하셔야\n회원가입이 완료 됩니다.",
      title: "약관동의 오류",
      event: null,
      eventText: "",
    });
  } else {
    set(errorModalAtom, {
      state: true,
      redirectUrl: "",
      text: "네트워크 오류가 발생했습니다.\n잠시 뒤에 시도해주세요.",
      title: "네트워크 오류",
      event: null,
      eventText: "",
    });
  }
});

export const whenMovedInitinalizedAtom = atom(null, (get, set) => {
  set(signupModelAtom, initialSignupModelAtom);

  set(emailSendingStateAtom, "");

  set(agreementAtom, [false, false, false, false]);
});

// group code 적용
export const applyGroupCodeAtom = atom(null, (get, set, groupCode: string) => {
  set(signupModelAtom, (current) => ({
    ...current,
    checkGroupCode: true,
    groupCode: groupCode,
  }));
});
