import { atom } from 'jotai';
import { needToChangePasswordModalAtom, needToChangePasswordModalInitState } from '../../Atoms/Login/NeedToChangePasswordModalAtom';
import { changePassword } from '../../Queries/Loginqueries';

export const needToChangePasswordModalPasswordTestAtom = atom((get) => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;
  return passwordRegex.test(get(needToChangePasswordModalAtom).password);
});

export const needToChangePasswordModalCloseAtom = atom(null, (_get, set) => {
  set(needToChangePasswordModalAtom, needToChangePasswordModalInitState);
})

export const needToChangePasswordModalChangePasswordAtom = atom(null, async (get, set) => {
  const modal = get(needToChangePasswordModalAtom);
  const accessToken = modal.accessToken;
  const refreshToken = modal.refreshToken;
  const password = modal.password;
  const confirmPassword = modal.confirmPassword;

  if (password !== confirmPassword) {
    alert("비밀번호가 일치하지 않습니다.");
    return;
  }

  const response = await changePassword(accessToken, refreshToken, password);

  if (response.status === 200) {
    set(needToChangePasswordModalAtom, (prev) => ({ ...prev, mode: "confirm" }));
  } else {
    alert("비밀번호 변경에 실패했습니다. 다시 시도해주세요.");
  }
});
