import axios from "axios";

export async function checkUserEmail(email: string): Promise<number> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/checkemail`,
      {
        email: email,
        site_name: process.env.REACT_APP_SITE_NAME,
      }
    );
    return response.status;
  } catch (error) {
    return 400;
  }
}

export async function checkGroupCode(groupCode: string): Promise<boolean> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/verify_group_code/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          group_code: groupCode,
        }), // email 키값으로 이메일 주소를 전송
      }
    );

    if (!response.ok) {
      return false;
    }

    const data = await response.json();
    return data.results;
  } catch (error) {
    return false;
  }
}

export async function sendEmail(email: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/sendEmail/signup`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }), // email 키값으로 이메일 주소를 전송
      }
    );

    if (!response.ok) {
      return null;
    }

    const data = await response.json();
    return data.verifyNum;
  } catch (error) {
    return null;
  }
}

export async function sendResetPWEmail(email: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/sendEmail/resetPassword`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }), // email 키값으로 이메일 주소를 전송
      }
    );

    if (!response.ok) {
      return null;
    }

    const data = await response.json();
    return data.verifyNum;
  } catch (error) {
    return null;
  }
}

interface SignupProps {
  group_code?: string;
  email: string;
  password: string;
  site: string;
  membership: string;
  nickname: string;
  birth_day: Date | null;
  marketing_agreement: boolean;
}

export async function signup(user: SignupProps) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/signup`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );
    return response.ok;
  } catch (error) {
    return false;
  }
}

export async function signupHereToo(user: SignupProps) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/link`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );

    if (!response.ok) {
      return false;
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    return false;
  }
}
