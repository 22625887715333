import React from 'react';
import * as S from './styled';
import ImageSection from '../../Components/Common/ImageSection';
import FindPWInputSection from '../../Components/FindPW/Contents/InputSection';

const Modals = () => {
  return (
    <>
      {/* <NeedToChangePasswordModal /> */}
      {/* <SuccessModal /> */}
    </>
  )
}

const FindPW = () => {
  return (
    <S.Fullcontainer>
      <S.Wrapper>
        <ImageSection pathTo="/" />
        <FindPWInputSection />
      </S.Wrapper>
      <Modals />
    </S.Fullcontainer>
  )
}

export default FindPW;
