import { atom } from "jotai";

export interface UserProps {
  idx: number;
  id: string;
  membership: string;
  image: string;
  nickname: string;
  membership_end_date: Date;
}

export const userAtom = atom<UserProps | null>(null);

export const isFromLinkAtom = atom(false);
