import { atom } from 'jotai';

interface IApplyGroupCodeModal {
  open: boolean;
  groupCode: string;
  groupName: string;
  isVerified: boolean;
}

export const applyGroupCodeModalAtom = atom<IApplyGroupCodeModal>({
  open: false,
  groupCode: "",
  groupName: "",
  isVerified: false,
});
