import {
  Box,
  Button,
  IconButton,
  Input,
  InputBase,
  styled as muiStyled,
  Stack,
} from "@mui/material";
import styled from "styled-components";

export const SignupFullcontainer = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #ececec;
`;

export const Wrapper = styled.div`
  width: 85%;
  height: 90%;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 10px;
  display: flex;
  padding: 30px 40px;
  padding: 0;
  margin: 0;

  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    flex-direction: column;
  }
`;

// export const WrapperButton = styled.button`
//   width: 100%;
//   height: 45px;
//   background: ${process.env.REACT_APP_MAIN_COLOR};
//   border: none;
//   outline: none;
//   border-radius: 40px;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//   cursor: pointer;
//   font-size: 16px;
//   color: white;
//   font-weight: 700;
//   margin-top: 20px;
//   margin-bottom: 15px;

//   @media (max-width: 768px) {
//     height: 40px; // 모바일에서 높이 줄임
//     font-size: 14px; // 모바일에서 폰트 크기 줄임
//   }
// `;

// export const InputContainer = muiStyled(Box)(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   borderRadius: "30px",
//   padding: "10px 15px",
//   flex: 1,
//   width: "calc(100% - 32px)",
//   outline: "none",
//   boxShadow: "0 0 10px rgba(164, 159,248, 0.2)",
//   color: "#393193",
//   transition: "background 0.3s ease",
//   border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
//   "@media (max-width: 768px)": {
//     padding: "8px 12px", // 모바일에서 패딩 조정
//   },
// }));

// export const CustomInputBase = muiStyled(InputBase)(({ theme }) => ({
//   flex: 1,
//   marginLeft: "15px",
//   "@media (max-width: 768px)": {
//     marginLeft: "10px", // 모바일에서 마진 조정
//   },
// }));

// export const CustomSecretInputBase = muiStyled(Input)(({ theme }) => ({
//   flex: 1,
//   marginLeft: "15px",
//   "@media (max-width: 768px)": {
//     marginLeft: "10px", // 모바일에서 마진 조정
//   },
// }));

// export const CustomIconButton = muiStyled(IconButton)`
//     align-self: end;
//     @media (max-width: 768px) {
//         padding: 8px; // 모바일에서 패딩 줄임
//     }
// `;

// export const CustomStack = muiStyled(Stack)({
//   width: "100%",
//   marginBottom: "20px",
//   gap: "5px",
//   marginTop: "10px",
//   alignItems: "start",
//   "@media (max-width: 768px)": {
//     marginBottom: "15px", // 모바일에서 마진 조정
//     marginTop: "8px", // 모바일에서 마진 조정
//   },
// });

export const CustomTypeSelectStack = muiStyled(Stack)({
  width: "100%",
  gap: "30px",
  marginBottom: "10px",
  marginTop: "15px",
  alignItems: "center",
  "@media (max-width: 768px)": {
    gap: "20px", // 모바일에서 간격 줄임
    marginTop: "10px", // 모바일에서 마진 조정
  },
});
