import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import { applyGroupCodeModalAtom } from "../../../Atoms/Signup/ApplyGroupCodeModalAtom";
import * as S from "./styled";
import { applyGroupCodeModalClearAtom, applyGroupCodeModalSetGroupAtom } from "../../../ViewModels/Signup/ApplyGroupCodeViewModel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ApplyGroupCodeModal = () => {
  const [modal, setModal] = useAtom(applyGroupCodeModalAtom);
  const clearModal = useSetAtom(applyGroupCodeModalClearAtom);
  const applyGroupCode = useSetAtom(applyGroupCodeModalSetGroupAtom)

  return (
    <Dialog
      open={modal.open}
      onClose={() => setModal((prev) => ({ ...prev, open: false }))}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>그룹 코드 확인</DialogTitle>
      {
        modal.isVerified ?
          <DialogContent>
            <Typography>그룹명: {modal.groupName}</Typography>
          </DialogContent> :
          <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "20px" }}>
            <ErrorOutlineIcon sx={{ fontSize: 50, color: "red" }} />
            <Typography>사용이 불가능한 그룹코드입니다.</Typography>
          </DialogContent>
      }
      {
        modal.isVerified ?
          <DialogActions>
            <Button variant="text" color="inherit" onClick={clearModal} >재등록</Button>
            <S.CustomButton variant="contained" onClick={applyGroupCode}>적용</S.CustomButton>
          </DialogActions> :
          <DialogActions sx={{ display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", padding: "16px 8px" }}>
            <Button variant="contained" color="inherit" onClick={clearModal} >닫기</Button>
          </DialogActions>
      }
    </Dialog>
  )
}

export default ApplyGroupCodeModal;
