import React, { useEffect } from "react";
import * as S from "../Components/Signup/styled";
import { useAtom, useSetAtom } from "jotai";
import { whenMovedInitinalizedAtom } from "../ViewModels/SignupViewModel";
import SignupInputSection from "../Components/Signup/Contents/InputSection";
import { applyGroupCodeModalClearAtom } from "../ViewModels/Signup/ApplyGroupCodeViewModel";
import ApplyGroupCodeModal from "../Components/Signup/Modal/ApplyGroupCode";
import ImageSection from "../Components/Common/ImageSection";

const Modals = () => {
  return (
    <ApplyGroupCodeModal />
  )
}

export function Signup() {
  // 초기화 함수
  const [, whenMovedInitinalized] = useAtom(whenMovedInitinalizedAtom);
  const initializeApplyGroupCodeModal = useSetAtom(applyGroupCodeModalClearAtom);

  useEffect(() => {
    // 초기화 코드 삽입
    initializeApplyGroupCodeModal();
    return () => {
      whenMovedInitinalized();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <S.SignupFullcontainer>
      <S.Wrapper>
        <ImageSection pathTo="/" />
        <SignupInputSection />
      </S.Wrapper>
      <Modals />
    </S.SignupFullcontainer>
  );
}
